import React from 'react';

const RecaptchaDisclosure: React.FC = () => (
  <p className="t-size-s has-text-gray-dark t-links-underlined">
    This site is protected by reCAPTCHA and the Google{' '}
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
  </p>
);

export default RecaptchaDisclosure;
