import React from 'react';

interface PaidButtonProps {
  checkoutUrl: string;
}

const PaidButton: React.FC<PaidButtonProps> = ({ checkoutUrl }) => (
  <a href={checkoutUrl} target="_blank" rel="noopener noreferrer">
    <button
      // we add transition direction to element to address
      // a weird bug that seems to be preventing the transition
      // from being compiled from scss to the minified css
      style={{ transition: 'background-color 0.2s ease' }}
      className="c-newsletter-list__card-button-paid has-text-black has-bg-yellow t-lh-m t-size-s t-weight-bold t-sans is-rounded-l"
      type="button"
    >
      More details
    </button>
  </a>
);

export default PaidButton;
