import React from 'react';
import { render } from 'react-dom';

import NewsletterList from './components/NewsletterList';
import select from '../utils/select';
import dataCleaner from './utils/data-cleaner';

export default function buildNewsletterList({ selector, newsletters }) {
  const cleanNewsletters = dataCleaner(newsletters);
  render(<NewsletterList newsletters={cleanNewsletters} />, select(selector));
}
