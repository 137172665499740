import React from 'react';

interface IErrorProps {
  msg: string;
}

const ErrorMsg: React.FC<IErrorProps> = ({ msg }) => {
  return (
    <div className="c-newsletter-list__error">
      <p className="t-size-xs">{msg}</p>
    </div>
  );
};

export default ErrorMsg;
