import React from 'react';
import { EButtonState, INewsletterConstant } from './types';

// Adds an extra field to each newsletter to indicate its position
export const NEWSLETTER_SORT_ORDER = {
  primary: {
    'the-brief': 1,
    'we-the-texans': 2,
    'breaking-news-alerts': 3,
    briefweekly: 4,
  },
  roundup: {
    'election-weekly': 1,
  },
};

// special values to be added to certain newsletters
export const NEWSLETTER_SPECIAL_SETTINGS = {
  'the-brief': {
    promo: true,
    promoImg: {
      src: 'https://static.texastribune.org/media/files/e4bb4969c17ea9f6473a615e2ac33148/31%20-%20Uvalde%20Murals%20EL%2018.JPG',
      position: 'center 60%',
    },
  },
  'election-weekly': {
    promo: true,
    promoImg: {
      src: 'https://static.texastribune.org/media/files/65d75c4b70c78d32dfb47bfb7121fad8/TXLege-newsletter-lead-v2.jpg',
      position: 'center',
    },
  },
};

export const URL_TERMS_OF_SERVICE =
  'https://www.texastribune.org/about/terms-of-service/';
export const URL_PRIVACY_POLICY =
  'https://www.texastribune.org/about/privacy-policy/';

export const INITIAL_STATE = {
  selectedIds: {},
  isPending: false,
  didError: false,
  successMsg: null,
  errorMsg: null,
  showBanner: false,
  counts: {
    signedUp: 0,
    selected: 0,
  },
};

export const NEWSLETTER_SUBLISTS_TEXT: INewsletterConstant = {
  primary: {
    title: 'Get the best of the Tribune in your inbox',
    subtitle: (
      <div className="t-links-underlined">
        <p className="has-b-btm-marg">
          Select any of the free newsletters below. &nbsp;
          <br className="is-hidden-until-bp-m" />
          Then, enter your email address and click “Sign Up.”
        </p>
        <p>
          Learn more about our premium political newsletter,{' '}
          <a href="https://www.texastribune.org/theblast/">The Blast.</a>
        </p>
      </div>
    ),
  },
  roundup: {
    title: 'Weekly Roundups',
    subtitle:
      'Get the latest on our coverage by topic, delivered weekly to your inbox.',
  },
};

/* Because the future of The Blast is unclear, we're hardcoding it for now.
The paid newsletter section may be removed in the future. */
export const PAID_NEWSLETTERS = [
  {
    sortOrder: 1,
    name: 'The Blast',
    slug: 'the-blast',
    authors: undefined,
    promo: true,
    sublist: 'paid',
    deliveryFrequency: 'Mondays, Wednesdays and Fridays',
    description:
      'The Tribune’s politics team brings you insider intel, exclusive analysis and the best of the Tribune to help you get ahead and stay ahead.',
    hexColor: '',
    isAutomated: false,
    logo: 'newsletters/TT-subscription-email-notification.jpg',
    longDescription:
      'The Tribune’s politics team brings you insider intel, exclusive analysis and the best of the Tribune to help you get ahead and stay ahead.',
    mailchimpInterestId: '',
    series: undefined,
    showInStoryEditor: undefined,
    checkoutUrl: 'https://www.texastribune.org/theblast/',
  },
];

const BASE_BUTTON_CLASSES =
  'c-newsletter-list__card-button l-align-center-children t-lh-m t-size-s t-weight-bold t-sans is-rounded-l';

export const BUTTON_SCHEMA = {
  [EButtonState.Unselected]: {
    className: `${BASE_BUTTON_CLASSES} c-newsletter-list__card-button--unselected has-bg-white has-text-black`,
    text: 'Select',
    iconId: 'plus',
    disabled: false,
  },
  [EButtonState.Selected]: {
    className: `${BASE_BUTTON_CLASSES} has-bg-blue has-text-black`,
    text: 'Selected',
    iconId: 'tick',
    disabled: false,
  },
  [EButtonState.SignedUp]: {
    className: `${BASE_BUTTON_CLASSES} c-newsletter-list__card-button--signed-up has-bg-blue signed-up`,
    text: 'Signed up',
    iconId: 'tick',
    disabled: true,
  },
};
