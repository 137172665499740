import React, { useState } from 'react';
import validateEmail from '../../utils/validate-email';
import { IHandleSignUpObj } from '../types';
import BannerSuccess from './BannerSuccess';
import BannerContainer from './BannerContainer';
import BannerLoading from './BannerLoading';
import ErrorMsg from './ErrorMsg';
import BannerCount from './BannerCount';
import getComponentName from '../../utils/get-component-name';

interface IBannerProps {
  allIdsSignedUp: boolean;
  selectedIdCount: number;
  handleSignUp(arg0: IHandleSignUpObj): void;
  fetchSuccessMsg: string | null;
  fetchPending: boolean;
  fetchDidError: boolean;
  fetchErrorMsg: string | null;
  setValidationError(arg0: string | null): void;
  handleBannerClose(): void;
}

const Banner: React.FC<IBannerProps> = ({
  allIdsSignedUp,
  selectedIdCount,
  handleSignUp,
  fetchSuccessMsg,
  fetchPending,
  fetchDidError,
  fetchErrorMsg,
  setValidationError,
  handleBannerClose,
}) => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setValidationError('Please enter a valid email address.');
      return;
    }
    handleSignUp({
      email,
      phone,
    });
  };

  if (fetchPending) {
    return <BannerLoading />;
  }

  if (fetchSuccessMsg) {
    return (
      <BannerSuccess
        fetchSuccessMsg={fetchSuccessMsg}
        handleSubscribeMore={handleBannerClose}
        showSubscribeMoreButton={!allIdsSignedUp}
      />
    );
  }

  // If there are 0 newsletters selected we hide the innards of this
  // component so it appears blank. We do this because it makes the banner
  // look better as it slides out of view when a user deselects all previously
  // selected newsletters (they won't see a '0 newsletters selected' message)
  const hiddenDetailsClass =
    selectedIdCount === 0
      ? 'c-newsletter-list__banner-container-signup--hidden'
      : '';

  return (
    <div
      className={`c-newsletter-list__banner-container-signup l-container--xl ${hiddenDetailsClass}`}
    >
      <div className="c-newsletter-list__banner-text-container">
        <BannerCount count={selectedIdCount} />
        <div className="t-lh-b">
          Enter your email and click “Sign Up” to start receiving newsletters.
        </div>
      </div>
      <form
        className="c-newsletter-list__banner-form-container"
        onSubmit={handleSubmit}
      >
        <input
          id="email"
          name="email"
          aria-label="email address to link"
          type="email"
          className="c-text-input__input l-display-block l-width-full t-size-xs has-text-gray-dark"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          className="is-hidden"
          type="text"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
        <input
          type="submit"
          disabled={email.length === 0}
          className="c-newsletter-list__banner-submit-button"
          value="Sign up"
        />
        {fetchDidError && fetchErrorMsg && <ErrorMsg msg={fetchErrorMsg} />}
      </form>
    </div>
  );
};

interface IWithBannerProps extends IBannerProps {
  showBanner: boolean;
}

const withBanner = (Component: React.FC<IBannerProps>) => {
  const NewComponent = (props: IWithBannerProps) => {
    const { showBanner, handleBannerClose, ...rest } = props;
    return (
      <BannerContainer show={showBanner} handleBannerClose={handleBannerClose}>
        <Component handleBannerClose={handleBannerClose} {...rest} />
      </BannerContainer>
    );
  };
  NewComponent.displayName = `withBanner(${getComponentName(Component)})`;
  return NewComponent;
};

export default withBanner(Banner);
