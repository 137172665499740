import React from 'react';
import { INewsletterAlt } from '../../types';
import { ISelectedIds, EButtonState, ThandleAddNewsletter } from '../types';
import SignUpButton from './SignUpButton';
import NewsletterContainer from './NewsletterContainer';

interface INewsletterProps {
  data: INewsletterAlt;
  handleAddNewsletter: ThandleAddNewsletter;
  buttonState: EButtonState;
}

const Newsletter: React.FC<INewsletterProps> = ({
  data,
  handleAddNewsletter,
  buttonState,
}) => {
  const handleClick = () => {
    if (buttonState === EButtonState.SignedUp) {
      // if already signed up then do nothing
      return;
    }
    const newButtonState =
      buttonState !== EButtonState.Selected
        ? EButtonState.Selected
        : EButtonState.Unselected;
    const newInterestIdsObj: ISelectedIds = {
      [data.mailchimpInterestId]: newButtonState,
    };
    handleAddNewsletter(newInterestIdsObj);
  };
  return (
    <NewsletterContainer
      name={data.name}
      deliveryFrequency={data.deliveryFrequency}
      description={data.longDescription}
      promo={data.promo}
      promoImg={data.promoImg}
      button={
        <SignUpButton handleClick={handleClick} buttonState={buttonState} />
      }
    />
  );
};

export default React.memo(Newsletter);
