export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Convert snake case string to camel case string.
 *
 * @param {String} string
 * @returns {String}
 */
export const toCamelCase = (e) => {
  return e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};
