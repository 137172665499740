import React from 'react';

interface IBannerContainerProps {
  show: boolean;
  children?: React.ReactNode;
  handleBannerClose?: () => void;
}

const BannerContainer: React.FC<IBannerContainerProps> = ({
  children,
  show,
  handleBannerClose,
}) => {
  const newsletterBannerClass = show ? '' : 'banner-hidden';
  return (
    <div
      className={`c-newsletter-list__banner-container has-bg-black has-text-white-off  ${newsletterBannerClass}`}
    >
      <div className="c-newsletter-list__banner-container-inner is-rounded-b">
        {children}
      </div>
      {handleBannerClose && (
        <div className="c-newsletter-list__banner-close">
          <button type="button" onClick={handleBannerClose}>
            <span className="c-icon has-text-gray">
              <span className="is-sr-only">Banner close button</span>
              <svg aria-hidden="true">
                <use href="#close" />
              </svg>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default BannerContainer;
