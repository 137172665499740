import React from 'react';

interface ISelectAllButtonProps {
  handleSelectAll(): void;
  isAllSelected: boolean;
  disabled: boolean;
}

const SelectAllButton: React.FC<ISelectAllButtonProps> = ({
  isAllSelected,
  handleSelectAll,
  disabled,
}) => {
  // default text to 'Select all' if button is disabled
  const selectAllText =
    isAllSelected && !disabled ? 'Deselect all' : 'Select all';

  return (
    <button
      className="c-newsletter-list__select-all-button has-bg-black has-text-white t-lh-m t-size-s t-weight-bold t-sans is-rounded-l"
      type="button"
      onClick={handleSelectAll}
      disabled={disabled}
    >
      {selectAllText}
    </button>
  );
};

export default SelectAllButton;
