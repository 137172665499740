import { INewsletterAlt } from '../types';

export enum EActionType {
  SetPending = 'SET_IS_PENDING',
  SetSuccess = 'SET_SUCCESS',
  SetSelectedIds = 'SET_SELECTED_IDS',
  SetError = 'SET_ERROR',
  CloseBanner = 'CLOSE_BANNER',
}

export type TAction =
  | { type: EActionType.SetPending }
  | { type: EActionType.SetSelectedIds; selectedIds: ISelectedIds }
  | {
      type: EActionType.SetSuccess;
      successMsg: string;
      selectedIds: ISelectedIds;
    }
  | { type: EActionType.SetError; errorMsg: string | null }
  | { type: EActionType.CloseBanner };

export type Dispatch = (action: TAction) => void;

export interface ICounts {
  signedUp: number;
  selected: number;
}
export interface IState {
  selectedIds: ISelectedIds;
  didError: boolean;
  isPending: boolean;
  successMsg: string | null;
  errorMsg: string | null;
  showBanner: boolean;
  counts: ICounts;
}

export interface IHandleSignUpObj {
  email: string;
  phone: string;
}

export enum EButtonState {
  Unselected,
  Selected,
  SignedUp,
}

export interface INewsletterAltLookup {
  [key: string]: INewsletterAlt;
}

export interface ISelectedIds {
  [key: string]: EButtonState;
}

export interface ISignUpObj {
  email: string;
  phone: string;
  selectedIds: ISelectedIds;
  newsletterNames: string[];
  dispatch: Dispatch;
}

export interface IInterestIdObj {
  interestId: string;
  value: boolean;
}

export interface IInterestIdsObj {
  [key: string]: boolean;
}

export interface IButtonSchemaVal {
  className: string;
  text: string;
  icon: React.FC;
}

export interface INewsletterSublists {
  [key: string]: INewsletterAlt[];
}

export interface INewsletterConstant {
  [key: string]: {
    title?: string;
    subtitle?: React.ReactNode | string;
  };
}

export interface IButtonSchema {
  EButtonState: IButtonSchemaVal;
}

export interface ISelectedIdBool {
  [key: string]: boolean;
}

export type ThandleAddNewsletter = (a: ISelectedIds) => void;
