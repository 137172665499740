import React from 'react';
import Icon from '../../display/components/Icon';
import { BUTTON_SCHEMA } from '../constants';
import { EButtonState } from '../types';

interface ISignUpButtonProps {
  buttonState: EButtonState;
  handleClick?: () => void;
}

const SignUpButton: React.FC<ISignUpButtonProps> = ({
  handleClick,
  buttonState,
}) => {
  const buttonConfig = BUTTON_SCHEMA[buttonState];
  const { iconId } = buttonConfig;
  return (
    <button
      // we add transition direction to element to address
      // a weird bug that seems to be preventing the transition
      // from being compiled from scss to the minified css
      style={{ transition: 'background-color 0.2s ease' }}
      className={buttonConfig.className}
      type="button"
      onClick={handleClick}
      disabled={buttonConfig.disabled}
    >
      <Icon
        iconName={iconId}
        display={{
          srHidden: false,
          extraClasses: ['t-size-xxs', 'c-newsletter-list__card-button-icon'],
        }}
      />{' '}
      {buttonConfig.text}
    </button>
  );
};

export default SignUpButton;
