import React, { useEffect, useState, useMemo } from 'react';
import Newsletter from './Newsletter';
import SelectAllButton from './SelectAllButton';
import { ISelectedIds, EButtonState, ThandleAddNewsletter } from '../types';
import { INewsletterAlt } from '../../types';

interface NewsletterSubListProps {
  title?: string;
  subtitle?: string | React.ReactNode;
  newsletters: INewsletterAlt[];
  selectedIds: ISelectedIds;
  handleAddNewsletter: ThandleAddNewsletter;
}

const NewsletterSubList: React.FC<NewsletterSubListProps> = ({
  title,
  subtitle,
  newsletters,
  selectedIds,
  handleAddNewsletter,
}) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const selectAll = () => {
    // if all newsletters are selected, deselect all
    const selectedState = !isAllSelected
      ? EButtonState.Selected
      : EButtonState.Unselected;
    const interestIdsObj: ISelectedIds = newsletters.reduce(
      (accum: ISelectedIds, newsletter: INewsletterAlt) => {
        if (
          selectedIds[newsletter.mailchimpInterestId] === EButtonState.SignedUp
        ) {
          return accum;
        }
        // eslint-disable-next-line no-param-reassign
        accum[newsletter.mailchimpInterestId] = selectedState;
        return accum;
      },
      {}
    );
    handleAddNewsletter(interestIdsObj);
  };
  const allIds = newsletters.map(
    (newsletter: INewsletterAlt) => newsletter.mailchimpInterestId
  );
  const isSignedUpForAll = allIds.every(
    (id: string) => selectedIds[id] === EButtonState.SignedUp
  );
  const sortedNewsletters = useMemo(() => {
    return newsletters.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
  }, [newsletters]);
  useEffect(() => {
    // If every newsletter in the sublist is either selected or signed up,
    // we consider all newsletters 'selected'
    if (
      allIds.every(
        (id: string) =>
          selectedIds[id] === EButtonState.Selected ||
          selectedIds[id] === EButtonState.SignedUp
      )
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedIds, allIds]);
  return (
    <div>
      <div className="c-newsletter-list__sublist-header has-xs-padding has-m-btm-marg">
        <div className="c-newsletter-list__sublist-header-text">
          {title && <h1 className="t-sans t-size-xxl">{title}</h1>}
          {subtitle && (
            <h2 className="t-sans t-size-b t-lh-b t-weight-normal">
              {subtitle}
            </h2>
          )}
        </div>
        {newsletters.length > 1 && (
          <SelectAllButton
            isAllSelected={isAllSelected}
            handleSelectAll={selectAll}
            disabled={isSignedUpForAll}
          />
        )}
      </div>
      <div className="c-newsletter-list__card-grid">
        {sortedNewsletters.map((newsletterData) => {
          const buttonState: EButtonState =
            selectedIds[newsletterData.mailchimpInterestId];
          return (
            <Newsletter
              key={newsletterData.slug}
              data={newsletterData}
              buttonState={buttonState}
              handleAddNewsletter={handleAddNewsletter}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NewsletterSubList;
