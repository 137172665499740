import { ISelectedIds, EButtonState, ICounts } from '../types';

interface ICountSelectedIds {
  (arg0: ISelectedIds): ICounts;
}

const countSelectedIds: ICountSelectedIds = (selectedIds) => {
  return Object.values(selectedIds).reduce(
    (acc, value) => {
      if (value === EButtonState.SignedUp) {
        return { ...acc, signedUp: acc.signedUp + 1 };
      }
      if (value === EButtonState.Selected) {
        return { ...acc, selected: acc.selected + 1 };
      }
      return acc;
    },
    { signedUp: 0, selected: 0 }
  );
};

export default countSelectedIds;
