import React from 'react';

interface IBannerTextProps {
  count: number;
}

const BannerCount: React.FC<IBannerTextProps> = ({ count }) => {
  const pluralizedCount = count === 1 ? 'Newsletter' : 'Newsletters';
  return (
    <div className="c-newsletter-list__banner-count-container">
      <div className="c-newsletter-list__banner-count has-text-yellow t-serif">
        {count}
      </div>
      <div className="t-size-xl">
        {pluralizedCount} <br /> selected
      </div>
    </div>
  );
};

export default BannerCount;
