import axios from 'axios';
import createUtmString from '../../utils/create-utm-string';
import getRecaptchaToken from '../../utils/getRecaptchaToken';
import {
  RECAPTCHA_ACTION_SIGNUP,
  SUBSCRIBE_API_URL,
  NEWSLETTER_ERROR_FALLBACK_MSG,
} from '../../constants';
import ajaxer from '../../ajax';
import {
  EActionType,
  EButtonState,
  ISelectedIdBool,
  ISignUpObj,
} from '../types';

interface ISignUp {
  (arg0: ISignUpObj): Promise<void>;
}

/**
 * Signs up user to Tribune newsletters using the subscribe API.
 *
 * @param {ISignUpObj} signUpObj - Object containing data needed to sign up user.
 * @param {string} signUpObj.email - User's email address.
 * @param {string} signUpObj.firstName - User's first name.
 * @param {ISelectedIds} signUpObj.selectedIds - The newsletters to subscribe to.
 * @param {string[]} signUpObj.newsletterNames - A list of names of newsletters for Google Analytics
 * @param {function} signUpObj.dispatch - dispatch to handle loading, data and error states.
 *
 * @returns {Promise<void>}
 */
const signUp: ISignUp = async ({
  email,
  phone,
  selectedIds,
  newsletterNames,
  dispatch,
}) => {
  dispatch({ type: EActionType.SetPending });

  // The subscribe endpoint expects an object with keys of mailchimp interest Ids
  // and boolean values. We create an object with only the IDs of selected newsletters.
  const selectedIdsBool: ISelectedIdBool = {};
  Object.keys(selectedIds).forEach((key) => {
    if (selectedIds[key] === EButtonState.Selected) {
      selectedIdsBool[key] = true;
    }
  });

  // get recaptcha token
  let token;
  try {
    token = await getRecaptchaToken(RECAPTCHA_ACTION_SIGNUP);
  } catch (err) {
    dispatch({
      type: EActionType.SetError,
      errorMsg: NEWSLETTER_ERROR_FALLBACK_MSG,
    });
    return;
  }

  // prepare request body
  const payload = {
    interests: selectedIdsBool,
    email,
    honeypot: phone,
    ga_label: 'subscribe landing',
    utm_code: createUtmString(),
    recaptcha_token: token,
    recaptcha_action: RECAPTCHA_ACTION_SIGNUP,
  };
  const serializedPayload = JSON.stringify(payload); // prepare API payload

  // make request
  let res;
  try {
    res = await ajaxer.post(SUBSCRIBE_API_URL, serializedPayload);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.data?.detail) {
        // Retrieve error message from API response
        dispatch({
          type: EActionType.SetError,
          errorMsg: err.response.data.detail,
        });
        return;
      }
    }
    // If error is not an Axios error, or if error does not contain a message,
    dispatch({
      type: EActionType.SetError,
      errorMsg: NEWSLETTER_ERROR_FALLBACK_MSG,
    });
    return;
  }

  // handle success
  const { data: resContent } = res;
  if (resContent && resContent.success === true) {
    dispatch({
      type: EActionType.SetSuccess,
      successMsg: resContent.detail,
      selectedIds,
    });

    // GA event
    // Note: this handling is a bit ugly but we're taking this approach
    // to ensure the metrics we're recording are consistent with the prior
    // version of the sign up page. In future, this should probably be reconsidered.
    newsletterNames.forEach((newsletterName) => {
      window.dataLayer.push({
        event: 'customSubscribeSuccess',
        gaAction: newsletterName,
        gaLabel: 'subscribe landing',
      });
    });
    return;
  }

  // handle uncaught errors
  dispatch({ type: EActionType.SetError, errorMsg: resContent.detail });
};

export default signUp;
