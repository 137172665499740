import {
  capitalizeFirstLetter,
  toCamelCase,
} from '../../utils/text-formatters';
import {
  NEWSLETTER_SORT_ORDER,
  NEWSLETTER_SPECIAL_SETTINGS,
} from '../constants';

/**
 * Cleans the data from Django's CMS for use in the component. Note that this
 * includes many ugly hardcoded steps to manipulate the data into the desired form.
 * This should be considered a short term solution. Longterm it would be preferable for
 * the provided backend data to be formatted and structured correctly. The major things
 * happening here:
 *
 * - 'Coming soon' newsletter is removed
 * - Four new fields are added to each newsletter object
 * - Key names are converted from snake case to camel case
 * - Values for 'name' and 'delivery_frequency' are reformatted
 * - Special values are added to specific newsletters
 *
 * @param {string} data - The raw JSON data for the newsletter list
 * @returns {object} - The cleaned data for the newsletter list
 */
const dataCleaner = (data) => {
  const parsedNewsletters = JSON.parse(data);

  // First round of data manipulation, mostly focused on re-formatting
  // key names and values
  const formattedData = [];
  parsedNewsletters.forEach((item) => {
    // filter out 'coming soon' newsletters
    if (item.fields.slug === 'coming-soon') {
      return;
    }

    const newFields = {};
    // create a set of new fields and set defaults
    newFields.sublist = 'primary';
    newFields.promo = undefined;
    newFields.promoImg = undefined;

    Object.entries(item.fields).forEach(([key, value]) => {
      let newValue = value;

      // delivery frequency
      if (key === 'delivery_frequency') {
        if (!value) {
          newValue = 'Anytime';
        } else {
          newValue = capitalizeFirstLetter(value);
        }
      }

      // format newsletter names
      if (key === 'name') {
        if (value.match(/weekly roundup/gi)) {
          newValue = value.replace(/weekly roundup/gi, '');
        }
        if (!value.match(/breaking news/gi) && value.match(/alerts/gi)) {
          newValue = value.replace('Alerts', '');
        }
      }

      // handle 'roundup' sublists
      if (key === 'is_automated' && value) {
        newFields.sublist = 'roundup';
      }

      // convert all keys from snake case to camel case
      const camelKey = toCamelCase(key);
      newFields[camelKey] = newValue;
    });

    formattedData.push(newFields);
  });

  // second round of data manipulation, mostly focuses on
  // adding a 'sort' field
  const cleanedData = formattedData.map((item) => {
    let newItem = item;

    // Add a sort field based on a manually-created sort order
    if (
      NEWSLETTER_SORT_ORDER[item.sublist] &&
      NEWSLETTER_SORT_ORDER[item.sublist][item.slug]
    ) {
      newItem.sortOrder = NEWSLETTER_SORT_ORDER[item.sublist][item.slug];
    } else {
      // if no sort order is defined, then set item to end
      newItem.sortOrder = parsedNewsletters.length + 1;
    }

    // handle other special settings, eg. promo fields
    if (NEWSLETTER_SPECIAL_SETTINGS[newItem.slug]) {
      newItem = { ...newItem, ...NEWSLETTER_SPECIAL_SETTINGS[newItem.slug] };
    }
    return newItem;
  });
  return cleanedData;
};

export default dataCleaner;
