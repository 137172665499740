import React from 'react';

const BannerLoading: React.FC = () => {
  return (
    <div className="c-newsletter-list__banner-container-loading">
      <div className="c-loading c-loading--tight" aria-hidden="true" />
    </div>
  );
};

export default BannerLoading;
