import React from 'react';
import { INewsletterAltPaid } from '../../types';
import PaidButton from './PaidButton';
import NewsletterContainer from './NewsletterContainer';

interface INewsletterPaidProps {
  data: INewsletterAltPaid;
}

const NewsletterPaid: React.FC<INewsletterPaidProps> = ({ data }) => {
  return (
    <NewsletterContainer
      name={data.name}
      deliveryFrequency={data.deliveryFrequency}
      description={data.description}
      promo={data.promo}
      button={<PaidButton checkoutUrl={data.checkoutUrl} />}
    />
  );
};

export default NewsletterPaid;
