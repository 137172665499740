import React from 'react';
import { PromoImg } from '../../types';

interface INewsletterContainerProps {
  name?: string;
  deliveryFrequency?: string;
  description?: string;
  logo?: string;
  promo?: boolean;
  promoImg?: PromoImg;
  button: JSX.Element | JSX.Element[];
}

const NewsletterContainer: React.FC<INewsletterContainerProps> = ({
  name,
  deliveryFrequency,
  logo,
  description,
  promo,
  button,
  promoImg,
}) => {
  // promo'd newsletters take up two cols on wide screens
  const promoClassNameContainer =
    promo !== undefined ? 'c-newsletter-list__card-container--promo' : '';
  const promoClassNameImg =
    promo !== undefined ? 'c-newsletter-list__card-image--promo' : '';
  return (
    <div
      className={`c-newsletter-list__card-container has-border ${promoClassNameContainer}`}
    >
      {promoImg && (
        <img
          className="js-lazy-image--target"
          alt={`Promo for ${name} newsletter`}
          data-src={promoImg.src}
          style={{
            width: '100%',
            height: '185px',
            objectFit: 'cover',
            objectPosition: promoImg.position,
          }}
        />
      )}
      <div className="c-newsletter-list__card-inner-container">
        <div className="c-newsletter-list__card-text-container">
          <h3 className="t-size-xl t-weight-normal t-serif has-text-black-off has-xs-btm-marg">
            {name}
          </h3>
          <p className="has-text-gray-dark t-size-b has-xs-btm-marg">
            <i>{deliveryFrequency}</i>
          </p>
          <div className="c-newsletter-list__card-sub-container">
            <p className="t-size-s">{description}</p>
            {button}
          </div>
        </div>
        {logo && (
          <img
            className={`c-newsletter-list__card-image ${promoClassNameImg}`}
            src={`https://static.texastribune.org/media/${logo}`}
            alt={name}
          />
        )}
      </div>
    </div>
  );
};

export default NewsletterContainer;
