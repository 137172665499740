import React from 'react';
import Icon from '../../display/components/Icon';

interface IBannerSuccessProps {
  fetchSuccessMsg: string | null;
  handleSubscribeMore(): void;
  showSubscribeMoreButton: boolean;
}

const BannerSuccess: React.FC<IBannerSuccessProps> = ({
  fetchSuccessMsg,
  handleSubscribeMore,
  showSubscribeMoreButton,
}) => {
  return (
    <div className="c-newsletter-list__banner-success-container">
      <div className="c-newsletter-list__banner-success-subcontainer">
        <Icon
          iconName="tick-in-circle"
          display={{ extraClasses: ['t-size-m'] }}
        />
        <div>{fetchSuccessMsg}</div>
      </div>
      {showSubscribeMoreButton && (
        <button
          onClick={handleSubscribeMore}
          className="c-button has-text-black"
          type="button"
        >
          Subscribe to more newsletters
        </button>
      )}
    </div>
  );
};

export default BannerSuccess;
