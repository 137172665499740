import React, {
  useReducer,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { INewsletterAlt } from '../../types';
import {
  IHandleSignUpObj,
  EActionType,
  EButtonState,
  INewsletterAltLookup,
  INewsletterSublists,
  ISelectedIds,
  IState,
} from '../types';
import { createFetchReducer } from '../reducers';
import Banner from './Banner';
import signUp from '../utils/sign-up';
import {
  INITIAL_STATE,
  NEWSLETTER_SUBLISTS_TEXT,
  PAID_NEWSLETTERS,
} from '../constants';
import NewsletterSubList from './NewsletterSubList';
import NewsletterPaidSubList from './NewsletterPaidSubList';
import RecaptchaDisclosure from './RecaptchaDisclosure';

interface INewsletterListProps {
  newsletters: INewsletterAlt[];
}

const NewsletterList: React.FC<INewsletterListProps> = ({ newsletters }) => {
  // We create the initial state for our reducer lazily because we need the
  // mailchimp IDs from the 'newsletters' prop in order to build the
  // 'selectedIds' object.
  const lazyInitState = (initialState: IState): IState => {
    const initialSelectedIds: ISelectedIds = {};
    newsletters.forEach((newsletter: INewsletterAlt) => {
      initialSelectedIds[newsletter.mailchimpInterestId] =
        EButtonState.Unselected;
    });
    const newInitialState = { ...initialState };
    newInitialState.selectedIds = initialSelectedIds;
    return newInitialState;
  };

  const [
    {
      counts,
      selectedIds,
      successMsg,
      isPending,
      didError,
      errorMsg,
      showBanner,
    },
    dispatch,
  ] = useReducer(createFetchReducer(), INITIAL_STATE, lazyInitState);

  const [newsletterSublists, setNewsletterSublists] =
    useState<INewsletterSublists>({});

  const newsletterLookup: INewsletterAltLookup = useMemo(() => {
    return newsletters.reduce(
      (acc, newsletter) => ({
        ...acc,
        [newsletter.mailchimpInterestId]: newsletter,
      }),
      {}
    );
  }, [newsletters]);

  // we memoize to prevent unnecessary re-renders of NewsletterSubList
  const handleAddNewsletter = useCallback((interestIdsObj) => {
    dispatch({
      type: EActionType.SetSelectedIds,
      selectedIds: interestIdsObj,
    });
  }, []);

  const handleSignUp = async ({ email, phone }: IHandleSignUpObj) => {
    const newsletterNames: string[] = Object.keys(selectedIds)
      .filter((key) => selectedIds[key] === EButtonState.Selected)
      .map((id) => newsletterLookup[id].name);
    await signUp({ email, phone, selectedIds, newsletterNames, dispatch });
  };

  const setValidationError = (validationErrorMsg: string) => {
    dispatch({ type: EActionType.SetError, errorMsg: validationErrorMsg });
  };

  const handleBannerClose = () => {
    dispatch({ type: EActionType.CloseBanner });
  };

  const allIdsSignedUp = counts.signedUp === newsletters.length;

  useEffect(() => {
    // we break up our list of newsletters into two sublists for display purposes
    const newSublists: INewsletterSublists = {
      primary: [],
      roundup: [],
    };
    newsletters.forEach((newsletter) =>
      newSublists[newsletter.sublist].push(newsletter)
    );
    setNewsletterSublists(newSublists);
  }, [newsletters]);
  return (
    <div className="l-container has-page-padding has-xxxl-btm-marg">
      <div className="c-newsletter-list__container has-xxl-btm-marg">
        {Object.keys(newsletterSublists).map((sublistId) => {
          const filteredNewsletters = newsletterSublists[sublistId];
          const { title, subtitle } = NEWSLETTER_SUBLISTS_TEXT[sublistId];
          return (
            <NewsletterSubList
              key={sublistId}
              title={title}
              subtitle={subtitle}
              newsletters={filteredNewsletters}
              selectedIds={selectedIds}
              handleAddNewsletter={handleAddNewsletter}
            />
          );
        })}
        <NewsletterPaidSubList
          title="Premium Subscriptions"
          subtitle="Support the Tribune’s coverage by paying for a subscription to our flagship political newsletter."
          newsletters={PAID_NEWSLETTERS}
        />
      </div>
      <RecaptchaDisclosure />
      <Banner
        allIdsSignedUp={allIdsSignedUp}
        selectedIdCount={counts.selected}
        handleSignUp={handleSignUp}
        fetchSuccessMsg={successMsg}
        setValidationError={setValidationError}
        fetchPending={isPending}
        fetchDidError={didError}
        fetchErrorMsg={errorMsg}
        showBanner={showBanner}
        handleBannerClose={handleBannerClose}
      />
    </div>
  );
};

export default NewsletterList;
