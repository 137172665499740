import {
  EActionType,
  TAction,
  IState,
  EButtonState,
  ISelectedIds,
} from './types';
import countSelectedIds from './utils/count-ids';

// eslint-disable-next-line import/prefer-default-export
export function createFetchReducer() {
  return (state: IState, action: TAction): IState => {
    switch (action.type) {
      case EActionType.SetPending:
        return {
          ...state,
          isPending: true,
          didError: false,
          errorMsg: null,
          showBanner: true,
        };
      case EActionType.SetSuccess: {
        const newSelectedIds: ISelectedIds = {};
        Object.keys(action.selectedIds).forEach((key) => {
          if (action.selectedIds[key] === EButtonState.Selected) {
            newSelectedIds[key] = EButtonState.SignedUp;
          } else {
            newSelectedIds[key] = action.selectedIds[key];
          }
        });
        const counts = countSelectedIds(newSelectedIds);
        // we update the ids to show sign ups were successful
        return {
          ...state,
          isPending: false,
          didError: false,
          selectedIds: newSelectedIds,
          successMsg: action.successMsg,
          errorMsg: null,
          showBanner: true,
          counts,
        };
      }
      case EActionType.SetSelectedIds: {
        const newInterestIds: ISelectedIds = {
          ...state.selectedIds,
          ...action.selectedIds,
        };
        const counts = countSelectedIds(newInterestIds);
        const showBanner = counts.selected > 0;
        return {
          ...state,
          isPending: false,
          didError: false,
          selectedIds: newInterestIds,
          successMsg: null,
          errorMsg: null,
          showBanner,
          counts,
        };
      }
      case EActionType.SetError:
        return {
          ...state,
          isPending: false,
          didError: true,
          errorMsg: action.errorMsg,
          showBanner: true,
        };
      case EActionType.CloseBanner:
        return {
          ...state,
          showBanner: false,
        };
      default:
        return state;
    }
  };
}
