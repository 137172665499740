import React from 'react';

/**
 * Retrieves the display name of a React component.
 *
 * @param WrappedComponent - The React component for which to retrieve the display name.
 * @returns The display name of the component, or 'Component' if not found.
 */
const getComponentName = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent: React.ComponentType<any>
): string => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default getComponentName;
