import React from 'react';
import { INewsletterAltPaid } from '../../types';
import NewsletterPaid from './NewsletterPaid';

interface NewsletterPaidSubListProps {
  title?: string;
  subtitle?: string;
  newsletters: INewsletterAltPaid[];
}

const NewsletterPaidSubList: React.FC<NewsletterPaidSubListProps> = ({
  title,
  subtitle,
  newsletters,
}) => {
  return (
    <div>
      <div className="c-newsletter-list__sublist-header has-xs-padding has-m-btm-marg">
        <div className="c-newsletter-list__sublist-header-text">
          {title && <h3 className="t-sans t-size-xxl">{title}</h3>}
          {subtitle && (
            <p className="t-sans t-weight-normal t-size-b">{subtitle}</p>
          )}
        </div>
      </div>
      <div className="c-newsletter-list__card-grid">
        {newsletters.map((newsletterData) => {
          return (
            <NewsletterPaid key={newsletterData.slug} data={newsletterData} />
          );
        })}
      </div>
    </div>
  );
};

// No prop changes are expected so we memoize to prevent needless re-render
export default React.memo(NewsletterPaidSubList);
